<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  />
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { BOverlay } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { LOGIN_TYPE } from '@/const/type'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'SsoCallback',
  components: { BOverlay },
  data() {
    return {
      isLoading: false,
    }
  },
  async created() {
    // const { access_token, id_token } = this.$route.query
    const params = new URL(window.location.href.replace('#', '?')).searchParams
    const accessToken = params.get('access_token')
    const idToken = params.get('id_token')
    this.setToken(accessToken)
    this.setLoginType(LOGIN_TYPE.SSO)
    this.setIdToken(idToken)
    this.isLoading = true
    try {
      const user = await this.verifySso()
      if (user) {
        this.$ability.update(user.abilities)
        await this.$router.replace({ name: 'dashboard' })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không lấy được thông tin người dùng',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        useJwt.logoutSso()
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setToken: 'auth/SET_TOKEN',
      setLoginType: 'auth/SET_LOGIN_TYPE',
      setIdToken: 'auth/SET_ID_TOKEN',
    }),
    ...mapActions({
      verifySso: 'auth/verifySso',
    }),
  },
}
</script>
